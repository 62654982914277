/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.3/dist/css/bootstrap.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/@domchristie/turn@3.1.1/dist/turn.min.css
 * - /npm/sweetalert2@11.11.0/dist/sweetalert2.min.css
 * - /npm/izitoast@1.4.0/dist/css/iziToast.min.css
 * - /npm/daterangepicker@3.1.0/daterangepicker.min.css
 * - /npm/select2@4.1.0-rc.0/dist/css/select2.min.css
 * - /npm/ion-rangeslider@2.3.1/css/ion.rangeSlider.min.css
 * - /npm/swiper@11.1.4/swiper-bundle.min.css
 * - /npm/@yaireo/tagify@4.26.5/dist/tagify.min.css
 * - /npm/@yaireo/dragsort@1.3.2/dist/dragsort.css
 * - /npm/filepond@4.31.1/dist/filepond.min.css
 * - /npm/filepond-plugin-image-preview@4.6.12/dist/filepond-plugin-image-preview.min.css
 * - /npm/jssocials@1.5.0/dist/jssocials.min.css
 * - /npm/jssocials@1.5.0/dist/jssocials-theme-plain.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
