.jssocials-share-link {
  padding: .5em .6em;
  border-radius: 0.3em;
  border: 2px solid #acacac;
  color: #acacac;
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
          transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out; }
  .jssocials-share-link:hover, .jssocials-share-link:focus, .jssocials-share-link:active {
    border: 2px solid #939393;
    color: #939393; }

.jssocials-share-count-box {
  position: relative;
  height: 2.5em;
  padding: 0 .3em;
  margin-left: 0.4em;
  background: #f5f5f5;
  border-radius: 0.3em;
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
          transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out; }
  .jssocials-share-count-box:hover {
    background: #e8e8e8; }
    .jssocials-share-count-box:hover:after {
      border-color: transparent #e8e8e8 transparent transparent; }
  .jssocials-share-count-box:after {
    content: "";
    display: block;
    position: absolute;
    top: 0.75em;
    left: -0.4em;
    width: 0;
    height: 0;
    border-width: 0.5em 0.5em 0.5em 0;
    border-style: solid;
    border-color: transparent #f5f5f5 transparent transparent;
    -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
            transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out; }
  .jssocials-share-count-box .jssocials-share-count {
    line-height: 2.5em;
    color: #acacac; }
